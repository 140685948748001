<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="600px"
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <span class="text-h3">{{ $t("edit") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="mdMake.name"
                  dense
                  :label="$t('name_*')"
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="mdMake.is_active"
                  dense
                  :items="statusLists"
                  item-value="id"
                  item-text="name"
                  :label="$t('status_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                v-if="authUser.isAdmin"
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="mdMake.product"
                  dense
                  disabled
                  :items="['oem', 'fleet']"
                  item-text="name"
                  item-value="id"
                  :label="$t('select_product_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                v-if="authUser.isAdmin"
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="mdMake.tenant_id"
                  dense
                  disabled
                  :items="tenantsByProduct"
                  item-text="name"
                  item-value="id"
                  :label="$t('select_tenant_*')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <small>{{ $t("indicates_required_field") }}</small>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="toggleEdit()"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="isLoadingSave"
            @click="updateItem(mdMake)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  props: {
    isVisibleDialog: Boolean,
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },
    mdMake: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      isLoadingSave: false,

      statusLists: [
        {
          id: "1",
          name: "Active",
        },
        {
          id: "0",
          name: "Inactive",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsOEM: "tenantsManagement/getTenantsOEM",
      tenantsFLEET: "tenantsManagement/getTenantsFLEET",
    }),
    tenantsByProduct() {
      if (this.mdMake?.product) {
        if (this.mdMake.product == "oem") {
          return this.tenantsOEM;
        } else {
          return this.tenantsFLEET;
        }
      } else {
        return [];
      }
    },
  },
  // async mounted() {
  //   if (this.authUser.isAdmin) {
  //     await this.$store.dispatch("meta/getClients", this.mdMake.make_type);
  //   }
  // },
  methods: {
    async updateItem(mdMake) {
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;
        await this.$store
          .dispatch("mdMakes/update", mdMake)
          .then(() => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
            this.isLoadingSave = false;
            this.toggleEdit();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
  },
};
</script>
